<template>
  <v-container class="page-container" fill-height fluid grid-list-xl
  >
    <v-layout wrap id="configuration">
      <h1 class="page-title text-xs-left mb-3 w-100">Session Types</h1>
      <div class="flex-display flex-row flex-justify-end mb-3 w-100">
        <button class="btn add-new" @click="showSessionTypeDialog('add',null)">
          <em class="v-icon mdi mdi-plus"></em>
          {{ addSessionTypeButtonText }}
        </button>
      </div>

      <h6 class="text-right mb-3 w-100">Show inactive session types</h6>
      <div class="flex-display flex-row flex-justify-end mb-3 w-100">
        <label class="switch m-r-60">
          <input type="checkbox" name="inactive_sessions" @change="changeEvent($event)" v-model="showInActiveSessions" true-value="true" false-value="false">
          <span class="slider round"></span>
        </label>
      </div>

      <template v-if="sessionTypes">
        <table class="table">
          <thead>
          <tr>
            <th scope="" class="item-heading">Type</th>
            <th scope="" class="item-heading">Method</th>
            <th scope="" class="item-heading">Duration</th>
            <th scope="" class="item-heading">Status</th>
            <th scope=""></th>
            <th scope=""></th>
          </tr>
          </thead>
          <tbody>
          <tr  v-for="sessionType in sessionTypes"
               :key="sessionType.id" v-if="showInActiveSessions || sessionType.status">
            <td class="item-text text-capitalize">{{sessionType.type}}</td>
            <td class="item-text text-capitalize">{{ sessionType.formatted_method }}</td>
            <td class="item-text text-capitalize">{{ sessionType.formatted_duration }}</td>
            <td class="item-text text-capitalize">{{ sessionType.formatted_status }}</td>
            <td>
              <div class="item-actions d-flex">
                <button class="btn-edit"
                        @click="showSessionTypeDialog('edit',sessionType)">Edit
                </button>
              </div>
            </td>
            <td>
              <label class="switch">
                <input type="checkbox" name="status" v-on:change="updateSessionStatus($event, sessionType.id)" v-model="sessionType.status" true-value="1" false-value="0">
                <span class="slider round"></span>
              </label>
            </td>
          </tr>
          </tbody>
        </table>
      </template>

      <!-- session Type -->
      <add-session-type-v2 v-if="addSessionDialog"
                           :visible="addSessionDialog"
                           :session-types="sessionTypes"
                           :action-type="actionType"
                           :value="sessionType"
                           v-on:submitSessionType="getSessionTypes"
                           v-on:hide="hideAddSessionTyeModal"
      >
      </add-session-type-v2>
    </v-layout>

  </v-container>
</template>

<script>
import Confirmation from "../components/common/Confirmation";
import AddSessionTypeV2 from '../components/configuration/AddSessionTypeV2'

export default {
  name: "SessionTypesV2",
  components: {
    AddSessionTypeV2,
    Confirmation
  },
  data() {
    return {
      addSessionTypeButtonText: "New Session Type",
      actionType: "",
      addSessionDialog: false,
      sessionType: null, // object,
      sessionTypes: [],
      showInActiveSessions: false
    }
  },
  created() {
    this.getSessionTypes();
  },
  methods: {
    getSessionTypes() {
      let loader = this.$loading.show();
      this.addSessionDialog = false;
      this.$http.get(this.url + "session-types/v2", {}).then(response => {
        loader.hide();
        this.sessionTypes = response.data.session_types;
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message, "Error");
      })
    },

    updateSessionStatus(event, sessionId) {
      let loader = this.$loading.show();
      this.$http.put(this.url +  "session-types/v2/update-status", {
          status: event.target.checked  ? 1 : 0,
          session_id: sessionId
      }).then(response => {
        loader.hide();
        this.$toastr("success", response.data.message);
        this.getSessionTypes();
      }).catch(error => {
        loader.hide();
        this.$toastr("error", error.response.data.message);
      })
    },

    showSessionTypeDialog(action, value) {
      this.addSessionDialog = true;
      this.actionType = action;
      this.sessionType = value;
    },

    hideAddSessionTyeModal() {
      this.addSessionDialog = false;
      this.sessionType = null;
      this.actionType = "add";
    },

    changeEvent (event) {
      this.showInActiveSessions = !!event.target.checked
    }
  }
}
</script>
<style scoped>
.item-heading {
  color: #949494;
  font-family: metropolisRegular;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 16px !important;
}
.item-text{
  color: #2D2929;
  font-family: metropolisRegular;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 16px;
  text-transform: capitalize;
}
.btn-edit, .btn-delete{
  width: 25px !important;
}
</style>
